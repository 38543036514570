<template>
  <div class="careers">
    <div
      class="page-header d-flex align-items-center justify-content-center"
      style="background: url('img/careers/header-careers.jpg'); background-size: cover"
    >
      <div class="viewport">
        <h1>Join our team and take your career to the next level</h1>
      </div>
      <div class="overlay"></div>
    </div>
    <div class="viewport">
      <div class="work-with-us">
        <h3>Why work with us</h3>
        <div class="row">
          <div class="col-md-4">
            <img src="/img/icons/icons8-puzzle-100.png" alt="solutions" />
            <h3>Create exceptional solutions for our clients</h3>
            <p>
              When you join us, you get so much more than a great job. As well as providing
              enjoyable work surroundings, our deeply-set values allow our colleagues to thrive and
              respect each other - resulting in amazing work across the board.
            </p>
          </div>
          <div class="col-md-4">
            <img src="/img/icons/icons8-good-quality-100.png" alt="scandinavian business culture" />
            <h3>Enjoy a Scandinavian-inspired business culture</h3>
            <p>
              Whether you’re based in Bulgaria or Sweden, you’ll enjoy a progressive and modern way
              of working with ongoing training, development and a healthy work/life balance -
              together with regular teambuilding exercises, socials and more.
            </p>
          </div>
          <div class="col-md-4">
            <img src="/img/icons/icons8-earth-element-100.png" alt="solutions" />
            <h3>Grow and evolve with work that takes you further</h3>
            <p>
              We acquire interesting projects from business-leading companies in an international
              environment. By operating in compact teams with new technologies and skilled
              co-workers, there are endless possibilities for further developing your expertise.
            </p>
          </div>
        </div>
      </div>
      <div class="more d-flex">
        <div
          class="img"
          style="
            background: url('img/careers/working-style.jpg');
            background-size: cover;
            background-position: center;
          "
        ></div>
        <div class="text d-flex align-items-center">
          <div class="content">
            <h3>More about the Scandinavian way of working</h3>
            <p>
              The Scandinavian workplace culture is based on a mix of teamwork and individual
              responsibility. Instead of a strict hierarchy, each role in the group is viewed as
              equally important, where consensus is generally central to decision-making.
            </p>
            <p>
              Scandinavian companies are also known for prioritizing an employees’ private life,
              which is driven by the philosophy that happy, healthy staff are the most productive
              and efficient.
            </p>
          </div>
        </div>
      </div>
      <div class="values">
        <div class="header">
          <h3>Our Values</h3>
          <p>Our staff is Comstream. These are the values that are the basis everything we do:</p>
        </div>
        <div class="values-boxes d-flex justify-content-between">
          <div class="box">
            <img src="img/icons/icons8-development-skill-100.png" alt="icon" />
            We have the leading <strong>competence</strong> within our areas of work.
          </div>
          <div class="box">
            <img src="img/icons/icons8-bookmark-100.png" alt="icon" />
            We enjoy our work and are <strong>committed</strong> to do the outmost for our
            customers.
          </div>
          <div class="box">
            <img src="img/icons/technical-support-2x.png" alt="icon" />
            Our customers should feel that we are <strong>responsive</strong> and proactive.
          </div>
          <div class="box">
            <img src="img/icons/icons8-work-100.png" alt="icon" />
            We act with great <strong>professionalism</strong> in all aspects of our work
          </div>
          <div class="box">
            <img src="img/icons/icons8-heart-puzzle-100.png" alt="icon" />
            <strong>Team-work</strong> is the basis for achieving great results for our customers.
          </div>
        </div>
      </div>
      <div class="call-to-action-boxes d-flex justify-content-between">
        <div class="box" style="background: url(img/homepage/join-bg.jpg); background-size: cover">
          <div class="content">
            <h3>Submit your CV</h3>
            <p>
              Whether you want something long-term or are looking to freelance, simply submit your
              CV, and we’ll get in touch as soon as possible.
            </p>
            <router-link to="/submit-cv" class="btn btn-primary">Submit CV</router-link>
          </div>
          <div class="overlay"></div>
        </div>

        <div
          class="box"
          style="background: url(img/homepage/become-freelancer.jpg); background-size: cover"
        >
          <div class="content">
            <h3>Join our freelancer network</h3>
            <p>
              Do you want the freedom to work from wherever you are for leading technology
              companies? Talk to us about joining our freelancers’ program.
            </p>
            <router-link to="/freelancers" class="btn btn-primary">Read more</router-link>
          </div>
          <div class="overlay"></div>
        </div>

        <div
          class="box"
          style="background: url(img/homepage/Karlskrona.jpg); background-size: cover"
        >
          <div class="content">
            <h3>Relocating to Sweden</h3>
            <p>
              Scandinavia presents an attractive and rewarding work environment with strong labor
              and protection frameworks, and a healthy balance of work and private life.
            </p>
            <router-link to="/relocate-to-sweden" class="btn btn-primary">Read more</router-link>
          </div>
          <div class="overlay"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import titleMixin from '../mixins/titleMixin';
export default {
  mixins: [titleMixin],
  title: 'Join Our Team | Comstream',
  mounted() {
    setTimeout(() => {}, 500);
  },
};
</script>
<style lang="scss" scoped>
.careers {
  .work-with-us {
    padding: 70px 0;
    h3 {
      text-align: center;
      font-size: 2.375rem;
      font-weight: 700;
      margin-bottom: 20px;
      color: #383838;
    }
    .col-md-4 {
      text-align: center;
      padding: 0 30px;
      h3 {
        color: #000;
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
  .more {
    height: 600px;
    background: #b99168;
    margin: 20px 0;
    overflow: hidden;
    .img {
      width: 50%;
    }
    .text {
      flex: 1;
      .content {
        padding: 45px;
        h3 {
          text-align: center;
          font-size: 38px;
          font-weight: 700;
          color: #ffffff;
          margin-bottom: 30px;
        }
        p {
          font-size: 18px;
          color: #fff;
        }
      }
    }
  }
  .values {
    padding: 70px 0;
    .header {
      text-align: center;
      h3 {
        font-size: 38px;
        color: #303030;
        font-weight: 700;
      }
      p {
        font-size: 18px;
        color: #303030;
      }
    }
    .values-boxes {
      .box {
        width: 19.5%;
        text-align: center;
        img {
          display: block;
          margin: 20px auto;
          width: 100px;
          height: 100px;
        }
      }
    }
  }
  .call-to-action-boxes {
    padding: 70px 0;
    .box {
      position: relative;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32%;
      height: 20px;
      min-height: 300px;
      overflow: hidden;
      .content {
        text-align: center;
        padding: 0 30px 0 30px;
        z-index: 1;
        h3 {
          font-size: 24px;
          font-weight: 600;
          color: #fff;
        }
        p {
          font-size: 16px;
          color: #fff;
        }
      }
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        z-index: 0;
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .careers {
    .work-with-us {
      h3 {
        font-size: 2rem;
      }
    }
    .more {
      display: block !important;
      height: auto;
      .text {
        .content {
          h3 {
            font-size: 2rem;
          }
        }
      }
    }
    .values {
      .header {
        h3 {
          font-size: 2rem;
        }
      }
      .values-boxes {
        display: block !important;
        .box {
          width: 75%;
          margin: 12px auto;
        }
      }
    }
  }
}
</style>
